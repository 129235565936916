import React from 'react';


function Main() {
    return (
        <div>
            <div
                className="lg:h-[464px] md:h-[250px] h-[130px] bg-banerBgd  bg-cover bg-center  bg-no-repeat mt-5 md:mt-0  ">

            </div>
            <div className="max-w-[1450px] mx-auto  px-5 flex flex-col justify-center items-center ">

                <a className="font-bold bg-[#26a17c] text-black py-2 px-16 rounded-md mt-10"
                   href="https://tracker.bespokeaffiliates.com/link?btag=49686364_295917">
                    <button>Play Now</button>
                </a>
                <div className="text-start">
                    <h1 className="font-bold text-4xl mt-20 mb-10">FREE BET MATCH! Place your first bet with tether.bet and we will match your stake, deposited
                        into your account within 24 hours!</h1>
                    <p className="leading-10">Check out our wide variety and great odds today. <br/>
                       <span className="font-bold"> Create your tether.bet account now to take advantage of this limited time offer.</span> <br/>
                       <span className="font-bold"> Available until Friday 24th November. </span> <br/>
                        The minimum amount of the deposit should be $20. <br/>
                        The maximum bonus amount the player can receive in one day is $200.
                        The maximum payout from the Freebet will be $1000. <br/>
                    </p>
                    <h1 className="font-bold text-3xl my-10">Terms and Conditions</h1>
                    <ul className="list-disc list-inside ml-5 S">
                        <li className="mb-2">The bonus will be available on the player’s account within 24 hours when the conditions
                            are met.
                        </li>
                        <li className="mb-2">The bonus will be given only to players who have made the deposit.
                        </li>
                        <li className="mb-2">The minimum amount of the deposit should be $20.
                        </li>
                        <li className="mb-2">Minimum odds per selection 1.5. Only bets with qualifying with minimum odds can
                            participate in this promotion.
                        </li>
                        <li className="mb-2">Void bets shall not qualify for the promotion.
                        </li>
                        <li className="mb-2">
                            Cashed out bets shall not qualify for the promotion.
                        </li>
                        <li className="mb-2">
                            The maximum bonus amount the player can receive is $200.
                        </li>
                        <li className="mb-2">
                            The max payout from the freebet will be $1000.
                        </li>
                        <li className="mb-2">
                            Players who receive this bonus cannot receive or use other bonuses at the same time.
                        </li>
                        <li className="mb-2">
                            Freebet is not subject to a wagering requirement before cash-out.
                        </li>
                        <li className="mb-2">
                            The bonus will be valid for 24 hours.
                        </li >
                        <li className="mb-2">
                            Tether.bet reserves the right to change or cancel any offer at any time and for any
                            reason.
                        </li>
                        <li className="mb-2">
                            In accordance with Tether.bet's General Terms and Conditions, Tether.bet has the right to
                            close or suspend the accounts of users who seek to gain an unfair advantage by abusing
                            this promotion.
                        </li>
                        <li className="mb-2">
                            Tether.bet has the right to request documents such as identity documents, residence
                            certificates, etc. from its members if it’s necessary.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Main;