import React from "react";
import NavbarLogo from "../assets/NavbarLogo.png";
const NavBar = () => {
    return (
        <div className=" max-w-[1450px] mx-auto  flex flex-col md:flex-row items-center py-3  px-5">
            <img src={NavbarLogo} alt="" className="w-[150px]"/>

        </div>
    )
}

export default NavBar;